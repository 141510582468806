<template>
  <div id="manage-category-list" class="fx-main container pd-t-1-em">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.97"
      :z-index="2147480000"
    ></loading>
    <div class='wd-100' ref="listRef">
      <div class="input-group fx-main mg-1-bot">
        <button class="clickable btn" v-on:click="showModal()">Criar Nova Categoria</button>
      </div>
      <div class='main-grid mb-g-t-c-1 grid-space'>
      <div
        v-for="category in categories"
        :key="category.id"
        class="container-category fx-main manage-category-item default-border fx-a-i-cent gd-tp-c gd-tp-r shadow pd-2-em height-100"
        >

        <div class="manage-category-img circle shadow txt-align-center">
          <img class='circle' v-if="category.imageUrl" :src="category.imageUrl" :alt="category.name" />
        </div>

        <div class="manage-category-name txt-align-center">
          <span class="category-name">{{ category.name }}</span>
        </div>
        <div class="edit-category">
          <!-- <i class="fas fa-ellipsis-h"></i> -->
          <div class="manage-category-menu-modal fx-center 1fx-wrap">
            <div v-on:click="showModal(category.id)" class="clickable mg-right-1-em">
              <i class="fas fa-pencil-alt"></i>
            </div>
            <div v-on:click="deleteCategory(category.id)" class="clickable">
              <i class="fas fa-trash"></i>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
    <modal
      class="top-modal"
      name="category-manage-modal"
      :width="'85%'"
      :height="'80%'"
      :adaptive="true"
    >
      <div class="modal-header fx-main fx-end">
        <i class="fa fa-times" style="padding: 10px" aria-hidden="true" @click="hideModal"></i>
      </div>
      <div class="fx-main container wd-100">
            <div id="FormCategory" class="wd-100">
              <div class="input-group fx-main mg-bot-2em">
                <label class="mg-right-2-em" for="category_form_name">Nome</label>
                <input
                  class="default-border"
                  type="text"
                  id="category_form_name"
                  v-model="categoryModal.name"
                />
              </div>
              <div class="input-group fx-main mg-bot-2em">
                <label class="mg-right-2-em" for="category_form_description">Descrição</label>
                <textarea
                  class="default-border"
                  type="text"
                  id="category_form_description"
                  v-model="categoryModal.description"
                />
              </div>
              <div class="input-group fx-main mg-bot-2em fx-a-i-cent">
                <span class="mg-right-2-em">Visível</span>
                <div class="switch">
                 <input type="checkbox" class="switch__input" id="category_form_published"
              v-model="categoryModal.published"
                  :true-value="true"
                  :false-value="false">
              <label for="category_form_published" class="switch__label"></label>
          </div>
              </div>
              <div class="input-group fx-main mg-bot-2em">
                <label class="mg-right-2-em" for="name">Imagem</label>
                <input
                  class="default-border"
                  type="file"
                  @change="processFile($event)"
                />
              </div>
              <div class="input-group fx-main mg-bot-2em" v-if="categories">
                <label class="mg-right-2-em" for="category_form_categories">Categoria Pai</label>
                <select
                  class="default-border"
                  id="category_form_categories"
                  v-model="categoryModal.parent"
                >
                  <option :value="null"></option>
                  <option v-for="category in categoriesFiltered"
                    :key="category.id"
                    :value="category.id">
                    {{ category.name }}
                  </option>
                </select>
              </div>
              <div class="input-group fx-main mg-bot-2em">
                <label
                  class="mg-right-2-em"
                  for="categ_sequence_ord"
                >Ordem</label>
                <input
                  class="default-border"
                  type="number"
                  id="categ_sequence_ord"
                  v-model="categoryModal.sequence_ord"
                />
              </div>
              <div class="input-group fx-main mg-bot-2em">
                <button class="btn clickable" v-on:click="categoryModal.id ? updateCategory() : createCategory()">{{ categoryModal.id ? 'Alterar' : 'Criar' }}</button>
              </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<style scoped>

  .container-category {
    padding: .3em;
    border-radius: 10px;
    margin-bottom : 1em;
  }

  @media (max-width: 600px) {
    .mb-g-t-c-1 {
        grid-template-columns: 1fr;
    }
  }


  .height-100 {
    height: 100%;
  }

#manage-category-list .main-grid {
  grid-template-columns: 1fr !important; 
}

#manage-category-list .circle {
  width : 3em;
  height : 3em;
}
</style>

<script>
import Categories from "@/services/categories.js";
import util from "@/services/util";

// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet


function generate_category() {
  return {
    name: "",
    description: "",
    published: false,
    parent: null,
    sequence_ord: 0,
  };
}

export default {
  name: "manage-category-list",
  data: function() {
    return {
      isLoading : false,
      categories: [],
      categoryModal: generate_category(),
      binaryDataImg: [],
      searchParameters: {
        page: 0,
        perPage: 100,
      },
      loadingMore: false,
      loadIndex: 0,
      over: false,
    };
  },
  mounted() {
    this.listCategories();
    setTimeout(() => window.addEventListener("scroll", this.handleScroll), 500);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    listCategories: function(push = false) {
      if (push) {
        if (this.loadingMore || this.over) {
          return;
        }
        this.searchParameters.page++;
        this.loadingMore = true;
      } else {
        this.searchParameters.page = 0;
        this.loadingMore = false;
        this.over = false;
        this.loadIndex++;
      }
      Categories.list(this.searchParameters).then(categories => {
        categories.forEach(category => {
          category.imageUrl = Categories.getImageUrl(category);
        });
        if (!push) {
          this.categories = categories;
        } else {
          if (categories.length === 0) {
            this.over = true;
          }
          this.categories.push(...categories);
          this.$forceUpdate();
          setTimeout(() => (this.loadingMore = false), 100);
        }
      })
      .finally(() => {
        if (!push) {
          this.loadIndex--;
        }
      });
    },
    handleScroll(event) {
      if (!this.$refs.listRef) {
        return;
      }
      let screenHeight = screen.height;
      let scrollTop = document.documentElement.scrollTop;
      let listStart = util.findPos(
        this.$refs.listRef,
        document.documentElement
      ).y;
      let listEnd = listStart + this.$refs.listRef.scrollHeight;
      let diff = listEnd - scrollTop;
      let bottomOfWindow = diff <= screenHeight * 1.2;
      if (bottomOfWindow) {
        this.listCategories(true);
      }
    },
    showModal: function(categoryId) {
      this.binaryDataImg = [];
      if(categoryId) {
        this.loadIndex++;
        Categories.get(categoryId).then(category => {
          if(category.parent) {
            category.parent = category.parent.id;
          }
          this.categoryModal = category;
          this.$modal.show("category-manage-modal");
        }).finally(() => {
          this.loadIndex--;
        });
      } else {
        this.categoryModal = generate_category();
        this.$modal.show("category-manage-modal");
      }
    },
    hideModal: function() {
      this.$modal.hide("category-manage-modal");
    },
    updateImage: function(category_id) {
      let img = this.binaryDataImg[0];
      if(!img) {
        return;
      }
      this.$notify({
        type: "info",
        title: "Adicionando Imagem",
        text: "Adicionando Imagem na Categoria...",
      });
      this.loadIndex++;
      Categories.putImage(category_id, img).then(
        image => {
          this.$notify({
            type: "success",
            title: "Imagem Adicionada com Sucesso",
            text: "Imagem Adicionada com Sucesso na Categoria!",
          });
          this.categories = this.categories.map(p => {
            if(p.id == category_id && image) {
              p.image = image.id;
              p.imageUrl = Categories.getImageUrl(p);
            }
            return p;
          });
        },
        error => {
          this.$notify({
            type: "error",
            duration : 24000,
            title: "Erro ao Adicionar Imagem na Categoria!",
            text: util.stringifyAxiosError(error)
          });
        }
      ).finally(() => {
        this.loadIndex--;
      });
    },
    adaptForSend: function(category) {
      let cloned = JSON.parse(JSON.stringify(category));
      if(cloned.parent) {
        cloned.parent = { id: cloned.parent };
      }
      return cloned;
    },
    updateCategory: function() {
      this.loadIndex++;
      Categories.edit(this.adaptForSend(this.categoryModal)).then(
        category => {
          this.$notify({
            type: "success",
            title: "Categoria Atualizada",
            text: "Categoria Atualizada com Sucesso!"
          });
          category.imageUrl = Categories.getImageUrl(category);
          this.categories = this.categories.map(p =>
            p.id == this.categoryModal.id ? category : p
          );
          this.updateImage(category.id);
          this.hideModal();
        },
        error => {
          this.$notify({
            type: "error",
            duration : 24000,
            title: "Erro ao Editar Categoria",
            text: util.stringifyAxiosError(error)
          });
        }
      ).finally(() => {
        this.loadIndex--;
      });
    },
    createCategory: function() {
      this.loadIndex++;
      Categories.create(this.adaptForSend(this.categoryModal)).then(
        category => {
          this.$notify({
            type: "success",
            title: "Categoria Criada",
            text: "Categoria Criada com Sucesso!"
          });
          this.categories = [ ...this.categories, category ];
          this.updateImage(category.id);
          this.hideModal();
        },
        error => {
          this.$notify({
            type: "error",
            duration : 24000,
            title: "Erro ao Criar Categoria",
            text: util.stringifyAxiosError(error)
          });
        }
      ).finally(() => {
        this.loadIndex--;
      });
    },
    deleteCategory: function(id) {
      this.$swal({
        title: "Tem certeza que deseja APAGAR a Categoria?",
        text: "Não é possível reverter essa ação!",
        icon: "warning",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: false,
            visible: true,
            className: "",
            closeModal: true
          },
          confirm: {
            text: "Sim, pode apagar!",
            value: true,
            visible: true,
            className: "",
            closeModal: true,
            dangerMode: true
          }
        }
      }).then(result => {
        if (!result) {
          return;
        }
        this.loadIndex++;
        Categories.delete(id).then(
          category => {
            this.$notify({
              type: "success",
              title: "Categoria Excluída",
              text: "Categoria Excluída com Sucesso!"
            });
            this.categories = this.categories.filter(p => p.id != id);
          },
          error => {
            this.$notify({
              type: "error",
              title: "Erro ao Excluir Categoria",
              text: util.stringifyAxiosError(error)
            });
          }
        ).finally(() => {
          this.loadIndex--;
        });
      });
    },
    processFile(event) {
      this.binaryDataImg = event.target.files;
    }
  },
  components: {
    Loading
  },
  computed: {
    isLoading: function() {
      return this.loadIndex > 0;
    },
    categoriesFiltered: function() {
      if(!this.categoryModal) {
        return this.categories;
      }
      return this.categories.filter(category => category.id != this.categoryModal.id);
    },
  }
};
</script>